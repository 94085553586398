"use client"

import { createRef, useEffect, useMemo, useState } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import { clsx } from "clsx"
import { m } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { useElementSize, useHover, useInterval } from "usehooks-ts"

import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import CloseButton from "~/components/ui/CloseButton"
import type { LinkProps } from "~/components/ui/Link"
import RichText from "~/components/ui/RichText"
import { useBannerIsDisabled } from "~/managers/BannerManager/hooks"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type TUspBanner = {
  richtexts: SbRichtext[]
  link?: LinkProps
} & RecipeVariants<typeof css.Element>

export function UspBanner({ richtexts, backgroundColor, link }: TUspBanner) {
  const [disabled, setDisabled] = useBannerIsDisabled()
  const { ref, inView } = useInView()
  const [visibleItemIndex, setVisibleItemIndex] = useState(0)
  const hasAtLeastTwoItems = richtexts.length > 1

  const containerRef = createRef<HTMLDivElement>()
  const isHover = useHover(containerRef)
  const variants = {
    visible: { opacity: 1, zIndex: 1 },
    notVisible: { opacity: 0, zIndex: -1 },
  }

  useInterval(handleTextFade, isHover ? null : 4000)

  function handleTextFade() {
    if (!hasAtLeastTwoItems) return

    if (visibleItemIndex === richtexts.length - 1) {
      setVisibleItemIndex(0)
      return
    }
    setVisibleItemIndex((lastIndex) => lastIndex + 1)
  }

  const handleOnRemove = () => {
    setDisabled(true)
  }

  const [scrollY, setScrollY] = useState(0)
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", () => setScrollY(window.scrollY))
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", () => setScrollY(window.scrollY))
    }
  })

  const [bannerRef, { height }] = useElementSize<HTMLElement>()

  const cssVariables = useMemo(() => {
    return `body {
        --banner-height: ${height - (scrollY ?? 0)}px;
        --banner-displayed: ${Number((inView && !disabled) ?? 0)};
      }`
  }, [height, inView, scrollY])

  if (disabled) return null

  return (
    <>
      <style>{cssVariables}</style>
      <div ref={bannerRef} className={clsx(css.Element({ backgroundColor }))}>
        <div className={css.marker} ref={ref}></div>
        <div className={css.content} ref={containerRef}>
          <div className={css.dotsContainer}>
            {richtexts.map((_, index) => (
              <div
                key={`usp_item_${index}`}
                onClick={() => setVisibleItemIndex(index)}
                className={css.dot({ backgroundColor, state: index === visibleItemIndex ? "active" : "inactive" })}
              />
            ))}
          </div>
          <div className={css.links}>
            {richtexts.map((richtext, index) => (
              <m.div
                initial={{ opacity: index === 0 ? 1 : 0 }}
                animate={index === visibleItemIndex ? "visible" : "notVisible"}
                className={css.textContainer}
                variants={variants}
                key={`usp-banner-text-${index}`}
              >
                <RichText
                  className={clsx(
                    css.richtext,
                    sprinkles({
                      cursor: link?.href ? "pointer" : "default",
                      color: backgroundColor === "black" ? "white" : "black",
                    })
                  )}
                  render={richtext}
                  disableStyles
                />
              </m.div>
            ))}
          </div>
          <CloseButton
            className={clsx(css.closeButton, css.Element({ backgroundColor }))}
            width={10}
            theme={backgroundColor === "black" ? "white" : "black"}
            onClick={handleOnRemove}
          />
        </div>
      </div>
    </>
  )
}
