import { useState } from "react"
import clsx from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import { ALERT_CODES } from "~/lib/alert"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import useShopifyNewsletterMutation from "~/hooks/account/useShopifyNewsletterMutation"
import { useAlerts } from "~/hooks/useAlerts"
import { useFormWithMutation } from "~/hooks/useFormWithMutation"
import Checkbox from "~/components/ui/Checkbox"
import Input from "~/components/ui/Input"
import RichText from "~/components/ui/RichText"
import Spinner from "~/components/ui/Spinner"
import SquareCta from "~/components/ui/SquareCta"
import { globalCheckboxPreset } from "~/components/abstracts/Checkbox/utils/global-checkbox-preset"
import { globalInputPreset } from "~/components/abstracts/Input/utils/global-input-preset"
import LayoutForm from "~/components/abstracts/LayoutForm"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export type NewsletterMutationProps = {
  email: string
  acceptsMarketing?: boolean
}

type NewsletterProps = PropsWithClassName<{
  newsletterLegals: Nullish<SbRichtext>
  withNewsletterInput?: boolean
  onSuccess?(): void
  withSuccessAlert?: boolean
  onAlreadySubscribed?(): void
  withAlreadySubscribed?: boolean
}>

function Newsletter({
  className,
  newsletterLegals,
  onSuccess,
  onAlreadySubscribed,
  withSuccessAlert = false,
  withNewsletterInput = false,
  withAlreadySubscribed = false,
}: NewsletterProps) {
  const [alreadySubscribed, setIsAlreadySubscribed] = useState(false)
  const triggerAlert = useAlerts()

  const t = useTranslate()
  const { mutate: newsletterMutation, isLoading } = useShopifyNewsletterMutation({
    onSuccess: () => {
      onSuccess?.()
      setIsAlreadySubscribed(false)
      withSuccessAlert && triggerAlert(ALERT_CODES.NEWSLETTER_SUBSCRIBE)
    },
    onError: (err) => {
      if (err === ALERT_CODES.EMAIL_ALREADY_SUBSCRIBED) {
        setIsAlreadySubscribed(withAlreadySubscribed)
        return onAlreadySubscribed?.()
      }
      triggerAlert()
    },
  })

  const inputCheckbox = (
    <Checkbox
      errorClassname={css.newsletterInputError}
      inputWrapperClassName={css.inputWithButtonWrapper}
      preset={globalCheckboxPreset.acceptTerms}
    >
      <RichText className={css.PrivacyPolicyLabel} disableStyles render={newsletterLegals} />
    </Checkbox>
  )

  const newsletterInput = (
    <>
      <Input
        sprinklesCss={{ paddingLeft: 0 }}
        className={css.InputContainer}
        inputWrapperClassName={css.inputWithButtonWrapper}
        errorClassname={css.newsletterInputError}
        inputClassName={clsx(css.inputNewsletter, css.mailInput)}
        preset={globalInputPreset.email}
      >
        <button type="submit" aria-label={t("form_signup")} className={css.submitButton}>
          {isLoading ? <Spinner size={10} /> : t("form_signup")}
        </button>
      </Input>
      {inputCheckbox}
    </>
  )

  const defaultInput = (
    <>
      <Input className={css.InputContainer} inputClassName={css.inputNewsletter} preset={globalInputPreset.email} />
      {inputCheckbox}
      <SquareCta aria-label={t("aria_submit")} theme="backgroundBlack" fill>
        {t("form_signup")}
      </SquareCta>
    </>
  )

  const { form, onSubmit } = useFormWithMutation<NewsletterMutationProps>(newsletterMutation, {}, "newsletter")

  return (
    <LayoutForm form={form} onSubmit={onSubmit} className={clsx(css.form, className)}>
      {withNewsletterInput ? newsletterInput : defaultInput}
      {alreadySubscribed && <p className={css.message}>{t("newsletter_already_subscribed")}</p>}
    </LayoutForm>
  )
}

export { Newsletter, type NewsletterProps }
