"use client"

import { createContext, useContext, useEffect, useMemo, type PropsWithChildren } from "react"
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsString,
  parseAsStringLiteral,
  useQueryState,
  useQueryStates,
} from "nuqs"

import { SORT_BY_PARAM, SORT_BY_WITH_DEFAULT } from "~/lib/algolia/constants"
import type { AlgoliaProductHit, AlgoliaPromoCardHit, SortBy } from "~/lib/algolia/types"
import { FiltersRealTimeSelectionContext } from "~/providers/AlgoliaCustomContext/FiltersRealTimeSelection/index.client"
import { objectKeys } from "~/utils/object-keys"
import { objectValues } from "~/utils/object-values"

export const QUERY_PARAM_KEY = "q"

export type AlgoliaCustomContextState = {
  initialPage?: number
  initialData?: Partial<SearchResponse<AlgoliaProductHit | AlgoliaPromoCardHit>>
  initialSearchProps?: {
    filters?: string
    hitsPerPage?: number
    facets?: string[]
    analytics?: boolean
    clickAnalytics?: boolean
    ruleContexts?: string[]
  }
  isFiltering: boolean
  isSorting: boolean
  facets?: SearchResponse<AlgoliaProductHit | AlgoliaPromoCardHit>["facets"]
  sortBy: SortBy
  setSortBy: (sortBy: SortBy | null) => void
  selectedFilters: Record<string, string[]>
  setSelectedFilters: (selectedFilters: Record<string, string[]>) => void
  page: number
  pageParamAsShallow?: boolean
  setPage: (page: number) => void
  query: string
  setQuery: (query: string) => void
}

const AlgoliaCustomReactContext = createContext<Omit<AlgoliaCustomContextState, "initialPage" | "pageParamAsShallow">>({
  facets: {},
  initialData: {},
  isFiltering: false,
  isSorting: false,
  sortBy: "default",
  setSortBy: () => null,
  selectedFilters: {},
  setSelectedFilters: () => null,
  page: 1,
  setPage: () => null,
  query: "",
  setQuery: () => null,
})

export function useAlgoliaCustomContext() {
  return useContext(AlgoliaCustomReactContext)
}

export function AlgoliaCustomContext({
  children,
  facets,
  initialData,
  initialSearchProps,
  initialPage,
  pageParamAsShallow,
}: PropsWithChildren<
  Pick<
    AlgoliaCustomContextState,
    "facets" | "initialPage" | "initialData" | "initialSearchProps" | "pageParamAsShallow"
  >
>) {
  const [sortBy, setSortBy] = useQueryState(
    SORT_BY_PARAM,
    parseAsStringLiteral(SORT_BY_WITH_DEFAULT).withDefault("default").withOptions({ clearOnDefault: true })
  )

  const [page, setPage] = useQueryState(
    "page",
    parseAsInteger.withOptions({
      scroll: Boolean(pageParamAsShallow),
      shallow: Boolean(pageParamAsShallow),
      history: "push",
    })
  )

  const [query, setQuery] = useQueryState(QUERY_PARAM_KEY, parseAsString)

  const [selectedFilters, setSelectedFilters] = useQueryStates(
    objectKeys(facets ?? {})
      ?.filter(Boolean)
      ?.reduce((acc, key) => {
        return {
          ...acc,
          [key]: parseAsArrayOf(parseAsString, "%2C").withDefault([]).withOptions({ clearOnDefault: true }),
        }
      }, {}),
    {
      history: "push",
      shallow: true,
    }
  )

  const isSorting = Boolean(sortBy && sortBy !== "default")
  const isFiltering = objectValues(selectedFilters as Record<string, string[]>)?.some(
    (facetFilters) => facetFilters?.length > 0
  )

  useEffect(() => {
    if (initialPage) setPage(initialPage)
  }, [])

  const value = useMemo(() => {
    return {
      initialData,
      initialSearchProps,
      isFiltering,
      isSorting,
      facets,
      sortBy,
      setSortBy,
      selectedFilters,
      setSelectedFilters,
      page: page || initialPage || 1,
      setPage,
      query: query || "",
      setQuery,
    }
  }, [
    initialPage,
    initialData,
    initialSearchProps,
    isFiltering,
    isSorting,
    facets,
    sortBy,
    selectedFilters,
    page,
    query,
  ])

  return (
    <FiltersRealTimeSelectionContext>
      <AlgoliaCustomReactContext.Provider value={value}>{children}</AlgoliaCustomReactContext.Provider>
    </FiltersRealTimeSelectionContext>
  )
}
