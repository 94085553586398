"use client"

import type { ComponentProps } from "react"
import clsx from "clsx"

import type { SbNavigationTextColor } from "~/lib/storyblok/types"
import InlineCta from "~/components/ui/InlineCta"
import Icon from "~/components/abstracts/Icon"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { DesktopTrigger } from "~/components/globals/Navigation/components/Desktop/ClientDesktopTrigger"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

type NavigationLinksProps = {
  links: NonNullable<TNavigation>["links"]
  color: SbNavigationTextColor
} & ComponentProps<"div">

export function NavigationLinks({ className, links, color, ...props }: NavigationLinksProps) {
  if (!links) return null

  return (
    <div className={clsx(className)} {...props}>
      {links.map(
        ({ link, sublinks, isHighlighted }, index) =>
          link && (
            <DesktopTrigger links={links} index={index} key={index}>
              {index === 0 && (
                <Icon className={clsx(sprinkles({ marginRight: 10 }))} height={10} name={"Menu"} theme={color} />
              )}
              <InlineCta
                {...link}
                className={clsx(
                  sprinkles({ marginRight: 30 }),
                  text({ design: "neue-15", lineHeight: 1, color: isHighlighted ? "ui-900" : color })
                )}
                withLine={sublinks.length === 0}
                size="small"
              >
                <span className={sprinkles({ bgColor: isHighlighted ? "yellow-dolly" : "transparent" })}>
                  {link.children}
                </span>
              </InlineCta>
            </DesktopTrigger>
          )
      )}
    </div>
  )
}
