"use client"

import { createContext, useContext, useMemo, useState, type Dispatch, type PropsWithChildren } from "react"
import type { SetStateAction } from "jotai"

import { objectValues } from "~/utils/object-values"

type FiltersRealTimeSelectionState = Record<string, string[]>

const FiltersRealTimeSelectionReactContext = createContext<{
  amount: number
  currentSelection: FiltersRealTimeSelectionState
  setCurrentSelection: Dispatch<SetStateAction<FiltersRealTimeSelectionState>>
}>({
  amount: 0,
  currentSelection: {},
  setCurrentSelection: () => {},
})

export function useFiltersRealTimeSelection() {
  const context = useContext(FiltersRealTimeSelectionReactContext)

  if (!context) {
    throw new Error("useFiltersRealTimeSelection must be used within a FiltersRealTimeSelectionContext")
  }

  return context
}

export function FiltersRealTimeSelectionContext({ children }: PropsWithChildren) {
  const [currentSelection, setCurrentSelection] = useState<FiltersRealTimeSelectionState>({})

  const value = useMemo(() => {
    const currentlySelectedFiltersAmount = objectValues(currentSelection)
      ?.filter(Boolean)
      ?.reduce((acc, facet) => {
        return acc + (objectValues(facet)?.filter(Boolean)?.length || 0)
      }, 0)

    return { amount: currentlySelectedFiltersAmount, currentSelection, setCurrentSelection }
  }, [currentSelection])

  return (
    <FiltersRealTimeSelectionReactContext.Provider value={value}>
      {children}
    </FiltersRealTimeSelectionReactContext.Provider>
  )
}
