"use client"

import { type ComponentProps } from "react"
import clsx from "clsx"

import { useGetCart } from "~/hooks/useGetCart"
import CartPanel from "~/components/ui/CartPanel"
import Icon from "~/components/abstracts/Icon"
import { INPDodger } from "~/components/shared/INPDodger/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type CartCtaProps = ComponentProps<"button"> & { iconClassName?: string; color?: "ui-900" | "ui-00" }
export function CartCta({ className, iconClassName, color = "ui-900", ...props }: CartCtaProps) {
  const { add } = usePanel()
  const t = useTranslate()
  const { cartItemsNumber } = useGetCart()
  const hasItems = cartItemsNumber > 0
  const handleOnClick = () => {
    add(<CartPanel />)
  }

  return (
    <INPDodger>
      {({ cn, onTransition }) => (
        <button
          onClick={() => onTransition(handleOnClick)}
          aria-label={t("cta_cart")}
          className={clsx(
            className,
            cn,
            sprinkles({ position: "relative", cursor: "pointer", display: "flex", alignItems: "flex-end" })
          )}
          {...props}
        >
          <div className={css.content}>
            <Icon className={iconClassName} height={12} direction="right" name="ShoppingBag" theme={color} />{" "}
            {hasItems && (
              <span className={clsx(css.number({ visibility: hasItems }), sprinkles({ color }))}>
                {cartItemsNumber}
              </span>
            )}
          </div>
        </button>
      )}
    </INPDodger>
  )
}
