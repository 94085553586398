"use client"

import { useEffect } from "react"
import clsx from "clsx"
import { useInView } from "react-intersection-observer"

import { Accordion } from "@unlikelystudio/react-abstract-components"

import type { InferReturn } from "~/@types/generics"
import { isDarel, isPablo } from "~/lib/brand"
import useIsHomepage from "~/hooks/useIsHomepage"
import InlineCta from "~/components/ui/InlineCta"
import { Link } from "~/components/ui/Link"
import { Newsletter } from "~/components/ui/Newsletter"
import RichText from "~/components/ui/RichText"
import type serializeFooter from "~/components/globals/Footer/_data/serializer"
import { FooterColumn } from "~/components/globals/Footer/components/FooterColumn"
import { FooterReassurance } from "~/components/globals/Footer/components/FooterReassurance"
import { FooterSocials } from "~/components/globals/Footer/components/FooterSocials"
import { useFooterInView } from "~/components/globals/Footer/hooks"
import { NavigationLogo } from "~/components/globals/Navigation/components/ClientNavigationLogo"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

export type FooterComponentProps = NonNullable<InferReturn<typeof serializeFooter>>

export function Footer({
  logoAlt,
  reassurance,
  socials,
  columns,
  legalsLinks,
  copyright,
  newsletterLegals,
  newsletterSubtitle,
}: FooterComponentProps) {
  const t = useTranslate()
  const [footerRef, footerInView] = useInView()
  const [_, setFooterInView] = useFooterInView()
  const isHomepage = useIsHomepage()

  useEffect(() => {
    setFooterInView(footerInView)
  }, [footerInView])

  return (
    <div className={css.Footer}>
      {reassurance && <FooterReassurance {...reassurance} />}
      <div className={css.FooterCenter}>
        <div className={css.FooterCenterLeft}>
          <div className={showFrom("desktop", "inline-flex")}>
            <NavigationLogo iconClassName={css.logoIcon({ darel: isDarel })} logoAlt={logoAlt} footer />
          </div>
          <p className={css.newsletterTitle}>{t("newsletter")}</p>
          <RichText render={newsletterSubtitle} className={sprinkles({ marginBottom: 16 })} />
          <Newsletter
            className={sprinkles({ backgroundColor: "white" })}
            newsletterLegals={newsletterLegals}
            withNewsletterInput
            withAlreadySubscribed
            withSuccessAlert
          />
          <div className={showFrom("desktop", "inline-flex")}>
            <FooterSocials className={css.desktopSocials} {...socials} />
          </div>
        </div>

        <Accordion className={css.FooterCenterRight} autoHide={true}>
          {columns.map((column, index) => {
            return <FooterColumn key={`footer_column_${index}`} index={index} className={css.columnItem} {...column} />
          })}
          <FooterColumn
            index={columns.length}
            className={clsx(css.columnItem, css.legalColumnItem)}
            title={t("footer_legals")}
            links={legalsLinks}
          />
        </Accordion>
      </div>
      {isPablo && (
        <div className={hideFrom("desktop", "block")}>
          <NavigationLogo iconClassName={css.logoIcon({ darel: isDarel })} logoAlt={logoAlt} footer />
        </div>
      )}
      <div className={css.FooterBottom} ref={footerRef}>
        <div className={clsx(css.FooterBottomLeft, showFrom("desktop", "block"))}>
          {copyright && <p className={css.footerBottomText}>{copyright}</p>}
          <div className={css.links}>
            <Link
              className={css.unlikelyBottomText}
              href="https://unlikely.technology"
              target="blank"
              obfuscate={!isHomepage}
            >
              tech by Unlikely Technology
            </Link>
            <Link className={css.unlikelyBottomText} href="https://numbered.com" target="blank" obfuscate={!isHomepage}>
              design by Numbered Studio
            </Link>
          </div>
        </div>

        <div className={css.FooterBottomRight}>
          {legalsLinks.length > 0 && (
            <div className={clsx(css.footerLegals, showFrom("desktop", "flex"))}>
              {legalsLinks.map(({ link }, index) => (
                <InlineCta key={`footer_legal_link_${index}`} size="tiny" className={css.footerBottomText} {...link} />
              ))}
            </div>
          )}
          <div className={clsx(css.socialsAndLangSwitcher, hideFrom("desktop", "flex"))}>
            <FooterSocials {...socials} />
          </div>
        </div>
      </div>
      <div className={css.links}>
        <Link
          className={clsx(css.mobileUnlikelyBottomText, hideFrom("desktop", "block"))}
          href="https://unlikely.technology"
          target="blank"
          obfuscate={!isHomepage}
        >
          tech by Unlikely Technology
        </Link>
        <Link
          className={clsx(css.mobileUnlikelyBottomText, hideFrom("desktop", "block"))}
          href="https://numbered.com/"
          target="blank"
          obfuscate={!isHomepage}
        >
          design by Numbered Studio
        </Link>
      </div>
    </div>
  )
}
