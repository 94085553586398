"use client"

import { forwardRef, useCallback, useState, type LegacyRef } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { getCountry, getLang } from "~/lib/i18n/utils/get-i18n"
import useLocale from "~/hooks/useLocale"
import { getLanguagesOptionsByCountry } from "~/components/ui/LangSwitcher/utils/get-languages-options-by-country"
import Confirm from "~/components/ui/MarketPopin/Confirm"
import Selector from "~/components/ui/MarketPopin/Selector"
import { getMarketRedirect, marketCookie } from "~/components/ui/MarketPopin/utils"
import PopinHeader from "~/components/ui/PopinHeader"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePopin } from "~/managers/PopinManager"

import * as css from "./styles.css"

export type TWrapper = { type?: "selector" | "confirm"; onRemove?(): void }

export type WrapperProps = PropsWithClassName<TWrapper>

function Wrapper(
  { className, type: defaultType = "selector", onRemove }: WrapperProps,
  ref: LegacyRef<HTMLDivElement>
) {
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const t = useTranslate()
  const [type, setType] = useState<TWrapper["type"]>(defaultType)
  const { removeCurrent } = usePopin()

  const defaultCountry = marketCookie.country.get() ?? getCountry(locale)

  const defaultLanguage = getLang(locale)

  const countryState = useState<string>(defaultCountry)
  const languageState = useState<string>(defaultLanguage)
  const languagesOptionsState = useState(getLanguagesOptionsByCountry(defaultCountry))

  const handleOnClick = useCallback(
    ({ lang, country }: { lang: string; country: string }) => {
      const redirectPath = getMarketRedirect({
        lang,
        country,
        locale,
        pathname,
        searchParams,
        shouldRedirectToHomePage: country !== defaultCountry,
      })

      if (redirectPath) window.location.href = redirectPath

      marketCookie.country.set(country)
      marketCookie.language.set(lang)

      removeCurrent()
      onRemove?.()
    },
    [locale, pathname, searchParams, removeCurrent, onRemove]
  )

  return (
    <div ref={ref} className={clsx(className, css.Wrapper)}>
      <PopinHeader className={css.header} title={t("market_title")} onRemove={onRemove} />

      {type === "confirm" && (
        <Confirm
          country={countryState[0]}
          languageState={languageState}
          languagesOptions={languagesOptionsState[0]}
          onSwitchClick={() => setType("selector")}
          onClick={handleOnClick}
        />
      )}

      {type === "selector" && (
        <Selector
          countryState={countryState}
          languageState={languageState}
          languagesOptionsState={languagesOptionsState}
          onClick={handleOnClick}
        />
      )}
    </div>
  )
}

export default forwardRef<HTMLDivElement, WrapperProps>(Wrapper)
