"use client"

import { Suspense } from "react"
import clsx from "clsx"
import { useInView } from "react-intersection-observer"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { isDarel, isPablo } from "~/lib/brand"
import useIsHomepage from "~/hooks/useIsHomepage"
import { LangSwitcher } from "~/components/ui/LangSwitcher"
import { Link } from "~/components/ui/Link"
import LoginPanel from "~/components/ui/Panels/Login"
import { SearchPanel } from "~/components/ui/Panels/Search"
import { useIsSearchPanelOpen } from "~/components/ui/Panels/Search/hooks"
import Icon from "~/components/abstracts/Icon"
import {
  useNavigationMobileVisibility,
  useNavigationTextColor,
  useNavigationTheme,
  usePanelIndex,
} from "~/components/abstracts/Navigation/hooks"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { BurgerCta } from "~/components/globals/Navigation/components/BurgerCta"
import { CartCta } from "~/components/globals/Navigation/components/ClientCartCta"
import { NavigationLinks } from "~/components/globals/Navigation/components/ClientNavigationLinks"
import { NavigationLogo } from "~/components/globals/Navigation/components/ClientNavigationLogo"
import { DesktopPanel } from "~/components/globals/Navigation/components/Desktop/ClientDesktopPanel"
import { INPDodger } from "~/components/shared/INPDodger/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useCurrentPanel } from "~/managers/NavigationManager"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { grid } from "~/styles/variants"

import * as css from "./styles.css"

export type NavigationComponentProps = { navigation: NonNullable<TNavigation> }

function NavigationComponent({ navigation }: NavigationComponentProps) {
  const t = useTranslate()
  // Getting the navigation states
  const [currentPanelIndex] = usePanelIndex()
  const [currentTheme] = useNavigationTheme()
  const [currentNavigationThemeColor] = useNavigationTextColor()
  const currentPanel = useCurrentPanel(navigation.links)
  const [inViewRef, inView] = useInView({ initialInView: true })
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useIsSearchPanelOpen()
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useNavigationMobileVisibility()

  // States
  const isPanelOpen = currentPanelIndex !== null && currentPanel
  const isScrolled = !inView
  const shouldDisplayBackground = currentTheme === "transparent" && (isPanelOpen || isScrolled || isSearchPanelOpen)

  const { add, removeCurrent } = usePanel()
  const { data: customer } = useGetCustomer()

  const isHomepage = useIsHomepage()

  function addSearchPanel() {
    setIsSearchPanelOpen(true)
    add(<SearchPanel />)
  }

  function removeSearchPanel() {
    setIsSearchPanelOpen(false)
  }

  const dynamicColor = shouldDisplayBackground || isBurgerMenuOpen || isPablo ? "ui-900" : currentNavigationThemeColor

  return (
    <>
      <div ref={inViewRef} className={clsx(css.navigationScrollMarker)} />
      <nav className={clsx(css.Navigation)}>
        <div className={sprinkles({ position: "relative" })}>
          <div className={sprinkles({ position: "absolute", width: "100%" })}>
            <div className={grid({ type: "fullWidth" })}>
              <header
                className={clsx(
                  css.NavigationHeader,
                  css.navigationTheme[shouldDisplayBackground ? "white" : currentTheme],
                  !inView && css.borderBottom,
                  isPanelOpen && css.hasNoTransition
                )}
              >
                <NavigationLinks
                  links={navigation.links}
                  color={dynamicColor}
                  className={sprinkles({ display: { desktop: "flex", mobile: "none" }, flex: "1", marginBottom: 3 })}
                />

                <NavigationLogo
                  iconClassName={css.logo({ darel: isDarel })}
                  logoAlt={navigation.logoAlt}
                  hasHeading1={isHomepage}
                  color={dynamicColor}
                />

                <div
                  className={sprinkles({
                    display: "flex",
                    flex: "1",
                    justifyContent: "flex-end",
                  })}
                >
                  <button
                    className={clsx(css.iconCta, sprinkles({ display: "inline-flex" }))}
                    aria-label={t("cta_search")}
                    type="button"
                    onClick={() => {
                      setIsBurgerMenuOpen(false)
                      removeCurrent()

                      if (isSearchPanelOpen) {
                        removeSearchPanel()
                        return
                      }

                      removeSearchPanel()
                      addSearchPanel()
                    }}
                  >
                    <Icon className={css.icon} direction="right" name="MagnifyingGlass" theme={dynamicColor} />
                  </button>

                  <LangSwitcher
                    className={clsx(css.iconCta, sprinkles({ display: { mobile: "none", desktop: "block" } }))}
                    onlyFlag
                  />

                  {customer ? (
                    <Link
                      className={clsx(css.iconCta, sprinkles({ display: { mobile: "none", desktop: "inline-flex" } }))}
                      {...navigation.accountLink}
                    >
                      <Icon className={css.icon} direction="right" name="Account" theme={dynamicColor} />
                    </Link>
                  ) : (
                    <INPDodger>
                      {({ cn, onTransition }) => (
                        <button
                          className={clsx(
                            cn,
                            css.iconCta,
                            sprinkles({ display: { mobile: "none", desktop: "inline-flex" } })
                          )}
                          onClick={() => onTransition(() => add(<LoginPanel />))}
                          aria-label={t("account_log_in")}
                          type="button"
                        >
                          <Icon className={css.icon} direction="right" name="Account" theme={dynamicColor} />
                        </button>
                      )}
                    </INPDodger>
                  )}

                  <CartCta
                    iconClassName={(css.icon, css.iconCart)}
                    className={clsx(css.iconCta)}
                    color={dynamicColor}
                  />

                  <BurgerCta
                    navigation={navigation}
                    className={clsx(
                      css.iconCta,
                      css.iconBurger,
                      sprinkles({
                        display: { desktop: "none" },
                      })
                    )}
                    color={dynamicColor}
                  />
                </div>
              </header>

              <Suspense>
                <DesktopPanel links={navigation.links} />
              </Suspense>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export { NavigationComponent }
