"use client"

import { useEffect, useRef, useState } from "react"
import clsx from "clsx"

import { Newsletter } from "~/components/ui/Newsletter"
import PopinWrapper from "~/components/ui/PopinWrapper"
import RichText from "~/components/ui/RichText"
import type { TPopinNewsletter } from "~/components/globals/PopinNewsletter/_data/serializer"
import { useShowNewsletterPopIn } from "~/components/globals/PopinNewsletter/components/hooks"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { Popin } from "~/managers/PopinManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

type PopinNewsletterTriggerProps = {
  popinNewsletter: NonNullable<TPopinNewsletter>
}

function PopinNewsletterTrigger({ popinNewsletter }: PopinNewsletterTriggerProps) {
  const showPopIn = useShowNewsletterPopIn(
    // check new URL param to know if page should display the url
    true,
    popinNewsletter
  )

  useEffect(() => {
    showPopIn()
  }, [showPopIn])

  return <div className={css.handler} />
}

type PopinNewsletterComponentProps = {
  onHide: () => void
  onSuccess?: () => void
  popinNewsletter: NonNullable<TPopinNewsletter>
}

function PopinNewsletterComponent({ onHide, popinNewsletter }: PopinNewsletterComponentProps) {
  const { image, title, subtitle, legals, success } = popinNewsletter
  const popinRef = useRef(null)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false)
  const t = useTranslate()

  return (
    <div className={css.PopinNewsletterWrapper} id="popin-newsletter">
      <Popin onRemove={onHide} clickOutsideRef={popinRef} className={css.PopinNewsletter} scrollDisabled={false}>
        <PopinWrapper
          image={image}
          onRemove={onHide}
          ref={popinRef}
          className={css.wrapper}
          containerClassName={css.container}
          headerBackground={false}
        >
          <RichText
            className={clsx(text({ design: "neue-18-22" }), sprinkles({ textAlign: "center" }))}
            render={title}
            disableStyles
          />
          <RichText
            className={clsx(text({ design: "neue-13", color: "grey-tundora" }), sprinkles({ textAlign: "center" }))}
            render={subtitle}
            disableStyles
          />
          <div>
            <Newsletter
              className={css.form}
              onSuccess={() => {
                setSubmitted(true)
                setAlreadySubscribed(false)
              }}
              onAlreadySubscribed={() => {
                setAlreadySubscribed(true)
              }}
              newsletterLegals={legals}
            />
            {submitted && success && (
              <RichText className={css.message({ success: true })} disableStyles render={success} />
            )}
            {alreadySubscribed && (
              <p className={css.message({ success: false })}>{t("newsletter_already_subscribed")}</p>
            )}
          </div>
        </PopinWrapper>
      </Popin>
    </div>
  )
}

export { PopinNewsletterComponent, PopinNewsletterTrigger }
