import { useRef, useState, type ReactNode } from "react"
import Cookies from "js-cookie"

import { COOKIE_KEYS } from "~/lib/constants"
import type { TPopinNewsletter } from "~/components/globals/PopinNewsletter/_data/serializer"
import { PopinNewsletterComponent } from "~/components/globals/PopinNewsletter/components"
import { usePopin } from "~/managers/PopinManager"

// Set the max iterations we show the popin newsetter before stop it
const MAX_ATTEMPTS = 1

/**
 * It shows the newsletter pop-in if it's not shown yet and if the user has not tried to close it yet
 * @param [props] - Partial<PopInOptions>
 * @returns A function that will show the pop in if it is not already shown.
 */
export function useShowNewsletterPopIn(withNewsletter: boolean, popinNewsletter: NonNullable<TPopinNewsletter>) {
  const { add, removeCurrent } = usePopin()
  const [showed, setShowed] = useState(false)
  const isAllowed = !showed && withNewsletter

  const popInRef = useRef<ReactNode>(null)
  const [hasNewsletter, setHasNewsletter] = useState(false)

  function onHide() {}

  function onSuccess() {
    if (popInRef?.current) {
      removeCurrent()
      setHasNewsletter(false)
    }
  }

  function showPopIn() {
    const popIn = <PopinNewsletterComponent onHide={onHide} onSuccess={onSuccess} popinNewsletter={popinNewsletter} />

    popInRef.current = popIn ?? null
    add(popIn)
    setHasNewsletter(true)
    setShowed(true)
  }

  const tryShowPopIn = () => {
    if (!hasNewsletter && isAllowed) {
      showPopIn()
    }
  }

  return tryShowPopIn
}

/**
 * Get the number of attempts to show the cookie pop-in
 * @param locale - The locale of the user.
 * @returns The number of attempts to show the cookie popup.
 */
export function getCookiesAttempts(locale: string) {
  const countAttemptsCookie = Cookies.get(COOKIE_KEYS.newsletterPopInAttempts(locale))
  return countAttemptsCookie ? parseInt(countAttemptsCookie) : 0
}

/**
 * It sets the number of attempts to show the newsletter pop-in.
 * @param locale - The locale you want to set the cookie for.
 */
export function setCookiesAttempts(locale: string) {
  const attempts = getCookiesAttempts(locale)
  if (attempts < MAX_ATTEMPTS)
    Cookies.set(COOKIE_KEYS.newsletterPopInAttempts(locale), `${attempts + 1}`, { expires: 30 })
}

export function checkIfAllowedToShowNewsletter(locale: string): boolean {
  const hasCookie = Cookies.get(COOKIE_KEYS.newsletterPopIn(locale)) === "1"

  return !hasCookie && getCookiesAttempts(locale) < MAX_ATTEMPTS
}
